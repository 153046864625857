import React, {useCallback, useEffect} from 'react'
import {isDonationLessThanMinimum} from '@wix/wix-events-commons-statics'
import {DonationInput} from '../../../../donation-input'
import {DONATION_ERROR} from '../../../../../types'
import {usePrevious} from '../../../../../../../../commons/hooks/use-previous'
import {classes} from './ticket-donation.st.css'
import {TicketDonationProps} from './interfaces'

export const TicketDonation = ({
  place,
  error,
  addPlaceDonation,
  setPlaceDonationError,
  showAccessibilityMode,
  chooseSeatMode,
}: TicketDonationProps) => {
  const {ticket, donation} = place
  const previousShowAccessibilityMode = usePrevious(showAccessibilityMode)

  useEffect(() => {
    if (previousShowAccessibilityMode !== showAccessibilityMode) {
      if (error === DONATION_ERROR.EMPTY_DONATION) {
        setPlaceDonationError({placeId: place.id, error: undefined})
      }
    }
  }, [showAccessibilityMode, previousShowAccessibilityMode, error])

  const onChange = useCallback(
    newDonation => {
      if (error) {
        setPlaceDonationError({placeId: place.id, error: undefined})
      }
      addPlaceDonation({placeId: place.id, donation: newDonation})
    },
    [error, setPlaceDonationError, addPlaceDonation, place.id],
  )

  const onBlur = useCallback(() => {
    if (chooseSeatMode && donation === undefined) {
      setPlaceDonationError({placeId: place.id, error: DONATION_ERROR.EMPTY_DONATION})
    }
    if (donation !== undefined && isDonationLessThanMinimum(ticket, donation)) {
      setPlaceDonationError({placeId: place.id, error: DONATION_ERROR.MINIMUM_NOT_REACHED})
    }
  }, [ticket, donation, setPlaceDonationError, place.id])

  return (
    <DonationInput
      ticket={ticket}
      onChange={onChange}
      onBlur={onBlur}
      value={donation}
      error={error}
      className={classes.root}
    />
  )
}
